@import "~simplebar/dist/simplebar.css";
@import "~highlight.js/styles/androidstudio.css";
@import "./@vex/styles/core";

/*
  You can override any CSS Variable or style here
  Example:

  :root {
   --sidenav-background: #333333;
  }

  All possible variables can be found in @vex/styles/core or by simply inspecting the element you want to change
  in Chrome/Firefox DevTools
*/
.red-snackbar {
  background: red;
}

.green-snackbar {
  background: green;
}

.subheading-3 {
  margin-bottom: .75rem;
}

a.link {
  text-decoration: underline;
  color: #5c77ff;
  &:hover {
    cursor: pointer;
  }
}

.angular-editor-textarea {
  ul {
    list-style: initial !important;
    padding: revert !important;
  }

  ol {
    list-style: decimal !important;
    padding: revert !important;
  }
}

.filter {
  border-radius: 0 !important;
  mat-card-content {
    margin-bottom: 0 !important;
    ::ng-deep {
      .mat-form-field-wrapper {
        margin-bottom: 0 !important;        
      }
      
    }      
  }
}

.mat-form-field-filter {
  ::ng-deep {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }    
}

.bg-app-bar {
  .bg-card {
    background-color: #FFF;
  }
  .mat-warn {
    display: flex !important;
  }
}

.vex-page-layout-header {
  background-color: #5c77ff !important;
  color: #FFF;
  .title {
    text-transform: uppercase;
  }
} 

@media print {
  body {
    font: 9pt sans-serif;
    line-height: 1.3;

    /* Avoid fixed header and footer to overlap page content */
    margin-top: 100px;
    margin-bottom: 50px;
  }

  .mat-drawer-container , .mat-drawer-content {
    overflow: visible !important;
  }
  .content {
    background-color: #FFF !important;
  }

  @page {
    @top-center { content: element(header) }
  }
}

